import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, NavParams, PopoverController } from '@ionic/angular';
import { ShareamongusersPage } from 'src/app/modal/shareamongusers/shareamongusers.page';
import { HttpService } from 'src/app/services/http.service';
import { PopupmodelService } from 'src/app/services/popupmodel.service';

@Component({
  selector: 'app-popovermodal',
  templateUrl: './popovermodal.component.html',
  styleUrls: ['./popovermodal.component.scss'],
})
export class PopovermodalComponent implements OnInit {
  row: any;
  constructor(
    private popoverCtrl: PopoverController,
    private navParams: NavParams,
    private alertController: AlertController,
    private popmodel: PopupmodelService,
    private httpservice: HttpService,
    private modalController: ModalController
  ) { 
    if(this.navParams.get('data')){
      this.row = JSON.parse(this.navParams.get('data'));
    }
  }
  ngOnInit() {

  }
  async shareAmoungUser(){
    this.popoverCtrl.dismiss();
    console.log(this.row);
    let params =  {};
    params['record'] = JSON.stringify(this.row);
    const modal = await this.modalController.create({
      component: ShareamongusersPage,
      componentProps: params
    });
    return await modal.present();
  }
  async ShareDocs(row: any){
      const alert = await this.alertController.create({
        cssClass: 'my-custom-class',
        header: 'Enter EmailId for share (add comma(,) for more than one share)',
        inputs: [
          {
            name: 'shareEmail',
            type: 'email',
            placeholder: 'Enter Email for share'
          }
        ],
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'doc-button-danger',
            handler: () => {
              console.log('Confirm Cancel');
            }
          }, {
            text: 'Ok',
            cssClass: 'doc-button-primary',
            handler: (data: any) => {
                if(data.shareEmail != ''){
                  this.shareFinal(row, data.shareEmail)
                }
                else {
                  this.popmodel.presentToast("Email Id is required for share.")
                }
            }
          }
        ]
      });
      await alert.present();
  }
  shareFinal(row: any, email: any){
    this.popmodel.presentLoading();
    let formdata = new FormData();
    row.email = email;
    formdata.append('params',JSON.stringify(row));
    formdata.append('table_name', 'myfiles');
    this.httpservice.postDataByForm("documents/sharedocs/"+row.downloadID, formdata)
        .then((data: any) => {
            this.popmodel.dismisLoading();
            if(data.status==1){
              this.popmodel.presentAlert("File Shared","Document file shared on email successfully.")         
            }
            else {
              this.popmodel.presentAlert('Action fail','Opps, Sorry action is fail due unespected reason. Try again');
            }
        });
  }
  shareByMail(){
    this.popoverCtrl.dismiss();
    this.ShareDocs(this.row);
  }
}
