import { Component } from "@angular/core";
import {
  Plugins,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed,
  StatusBarStyle,
} from "@capacitor/core";
const { PushNotifications, StatusBar, SplashScreen } = Plugins;
import { Platform } from "@ionic/angular";
// import { SplashScreen } from '@ionic-native/splash-screen/ngx';
// import { StatusBar } from '@ionic-native/status-bar/ngx';
import { HttpService } from "./services/http.service";
import { FileSharer } from "@byteowls/capacitor-filesharer";
import { registerWebPlugin } from "@capacitor/core";
//import { Router } from '@angular/router';

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  constructor(
    private platform: Platform,
    // private splashScreen: SplashScreen,
    // private statusBar: StatusBar,
    private httpservice: HttpService //private router: Router
  ) {
    this.initializeApp();
    this.httpservice.whiteLabelSettings();
  }
  initializeApp() {
    this.platform.ready().then(() => {
      sessionStorage.setItem("deviceWidth", this.platform.width().toString());
      sessionStorage.setItem("deviceHeight", this.platform.height().toString());
      // this.statusBar.styleDefault();
      // this.splashScreen.hide();
      SplashScreen.hide();
      if (this.platform.is("mobile")) {
        //this.initializeFCM();
        StatusBar.setStyle({ style: StatusBarStyle.Dark });
        StatusBar.setBackgroundColor({ color: "#047c92" });
        StatusBar.setOverlaysWebView({ overlay: false });
        sessionStorage.setItem("isMobile", "Yes");
      }
      registerWebPlugin(FileSharer);
    });
    // this.platform.backButton.subscribeWithPriority(-1, () => {
    //     const url = this.router.url;
    //     if (url === '/connect/home') {
    //         navigator['app'].exitApp();
    //     }
    // });
  }
  initializeFCM() {
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermission().then((result) => {
      if (result.granted) {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }
    });

    PushNotifications.addListener(
      "registration",
      (token: PushNotificationToken) => {
        sessionStorage.setItem("token", token.value);
        // alert('Push registration success, token: ' + token.value);
      }
    );

    PushNotifications.addListener("registrationError", (error: any) => {
      // alert('Error on registration: ' + JSON.stringify(error));
    });

    PushNotifications.addListener(
      "pushNotificationReceived",
      (notification: PushNotification) => {
        // alert('Push received: ' + JSON.stringify(notification));
      }
    );

    PushNotifications.addListener(
      "pushNotificationActionPerformed",
      (notification: PushNotificationActionPerformed) => {
        // alert('Push action performed: ' + JSON.stringify(notification));
      }
    );
  }
}
