import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular';
import { HttpService } from 'src/app/services/http.service';
import { PopupmodelService } from 'src/app/services/popupmodel.service';

@Component({
  selector: 'app-shareamongusers',
  templateUrl: './shareamongusers.page.html',
  styleUrls: ['./shareamongusers.page.scss'],
})
export class ShareamongusersPage implements OnInit {
  allUsers: any = [];
  selectedUser: any = [];
  sharedForm: FormGroup;
  selectedDoc: any;
  public columns: any;
  public rows: any;

  constructor(
    private httpservice: HttpService,
    private popupmodel: PopupmodelService,
    private modalCtrl: ModalController,
    private _formBuilder: FormBuilder,
    private navParams: NavParams
  ) { 

    if(this.navParams.get('record')){
        this.selectedDoc = JSON.parse(this.navParams.get('record'));
    }

    this.columns = [
      { name: 'Company_Name', title:'Company Name'},
      { name: 'Name', title:'Name'},
      // { name: 'Contact_No', title:'Contact No'},
      { name: 'City', title:'City'}
    ];

    this.sharedForm = this._formBuilder.group({
      View: '1',
      Upload: false,
      Download: false,
      Share: false,
      Delete: false,
      Manage: false
    })
  }
  getItems(event: any){
    let checked = event.detail.checked;
    let value = event.detail.value;
    if(checked){
      this.selectedUser.push(value.Id);
    }
    else {
      let index = this.getIndex(value.Id);
      this.selectedUser.splice(index,1);
    }
  }
  getIndex(selId: any){
    return this.selectedUser.findIndex(_ => _.Id == selId);
  }
  ngOnInit() {
    this.getMyUsers();
  }
  closeModal(){
    this.modalCtrl.dismiss();
  }
  getMyUsers(){
    this.popupmodel.presentLoading();
    let formdata = new FormData();
    formdata.append('User_Role',sessionStorage.getItem('User_Role'));
    let condition = {Parent_Id : sessionStorage.getItem('userId')};
    formdata.append('condition',JSON.stringify(condition));
    this.httpservice.postDataByForm("users/getuser",formdata)
        .then((data: any) => {
            this.popupmodel.dismisLoading();
            if(data.status==1){
              this.allUsers = data.data.records;
            }
        });
  }
  shareNow(){
    console.log(this.sharedForm.value);
    this.popupmodel.presentLoading();
    let formdata = new FormData();
    let records = {userId: sessionStorage.getItem('userId'), Shared_Doc_Id: this.selectedDoc.Id};
    formdata.append('records',JSON.stringify(records));
    formdata.append('permission',JSON.stringify(this.sharedForm.value));
    formdata.append('users',JSON.stringify(this.selectedUser));
    this.httpservice.postDataByForm("users/sharedocument",formdata)
        .then((data: any) => {
            this.popupmodel.dismisLoading();
            console.log(data);
            if(data.status==1){
                this.popupmodel.presentAlert("Shared successfully", data.message);
                this.closeModal();
            }
        });
  }
}
