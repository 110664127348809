import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'connect',
    loadChildren: () => import('./menus/menu/menu.module').then( m => m.MenuPageModule)
  },
  {
    path: 'uploaddocfile',
    loadChildren: () => import('./modal/uploaddocfile/uploaddocfile.module').then( m => m.UploaddocfilePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./authenticate/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'forgotpassword',
    loadChildren: () => import('./authenticate/forgotpassword/forgotpassword.module').then( m => m.ForgotpasswordPageModule)
  },
  {
    path: 'add-content',
    loadChildren: () => import('./pages/content/content.module').then( m => m.ContentPageModule)
  },
  {
    path: 'mnguser',
    loadChildren: () => import('./modal/mnguser/mnguser.module').then( m => m.MnguserPageModule)
  },
  {
    path: 'mngdocs',
    loadChildren: () => import('./modal/mngdocs/mngdocs.module').then( m => m.MngdocsPageModule)
  },
  {
    path: 'mngfinanceyr',
    loadChildren: () => import('./modal/mngfinanceyr/mngfinanceyr.module').then( m => m.MngfinanceyrPageModule)
  },
  {
    path: 'mngsubscribe',
    loadChildren: () => import('./modal/mngsubscribe/mngsubscribe.module').then( m => m.MngsubscribePageModule)
  },
  {
    path: 'uploadonedoc',
    loadChildren: () => import('./modal/uploadonedoc/uploadonedoc.module').then( m => m.UploadonedocPageModule)
  },
  {
    path: 'service-disabled',
    loadChildren: () => import('./authenticate/servicedisabled/servicedisabled.module').then( m => m.ServicedisabledPageModule)
  },
  {
    path: 'docviewer',
    loadChildren: () => import('./modal/docviewer/docviewer.module').then( m => m.DocviewerPageModule)
  },
  {
    path: 'shares',
    loadChildren: () => import('./pages/shares/shares.module').then( m => m.SharesPageModule)
  },
  {
    path: 'wholesearch',
    loadChildren: () => import('./pages/wholesearch/wholesearch.module').then( m => m.WholesearchPageModule)
  },
  {
    path: 'createwhitelabel',
    loadChildren: () => import('./modal/createwhitelabel/createwhitelabel.module').then( m => m.CreatewhitelabelPageModule)
  },
  {
    path: 'userpermissions',
    loadChildren: () => import('./modal/userpermissions/userpermissions.module').then( m => m.UserpermissionsPageModule)
  },
  {
    path: 'appspin',
    loadChildren: () => import('./modal/appspin/appspin.module').then( m => m.AppspinPageModule)
  },
  {
    path: 'new-user',
    loadChildren: () => import('./authenticate/new-user/new-user.module').then( m => m.NewUserPageModule)
  },
  {
    path: 'subscription-plans',
    loadChildren: () => import('./authenticate/subscription-plans/subscription-plans.module').then( m => m.SubscriptionPlansPageModule)
  }, 
  {
    path: 'billing-info',
    loadChildren: () => import('./authenticate/billing-info/billing-info.module').then( m => m.BillingInfoPageModule)
  },
  {
    path: 'shareamongusers',
    loadChildren: () => import('./modal/shareamongusers/shareamongusers.module').then( m => m.ShareamongusersPageModule)
  },
  {
    path: 'balpayment',
    loadChildren: () => import('./modal/balpayment/balpayment.module').then( m => m.BalpaymentPageModule)
  },
  {
    path: 'subscription-history',
    loadChildren: () => import('./modal/subscription-history/subscription-history.module').then( m => m.SubscriptionHistoryPageModule)
  },
  {
    path: 'outlet',
    loadChildren: () => import('./pages/outlet/outlet.module').then( m => m.OutletPageModule)
  },
  {
    path: '**',
    loadChildren: () => import('./authenticate/nonfound/nonfound.module').then( m => m.NonfoundPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
