import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { PopupmodelService } from "./popupmodel.service";

@Injectable({
  providedIn: "root",
})
export class HttpService {
  //serviceUrl: any = "http://localhost:8080/connect/connect-my-file-webservices/";
  //pageUrl: any = "http://localhost:8080/connect/connect-my-file-webservices/";
  // serviceUrl: any = "https://api.connectmyfile.com/apidemo/";
  // pageUrl: any = "https://api.connectmyfile.com/apidemo/";
  serviceUrl: any = "https://api.connectmyfile.com/";
  pageUrl: any = "https://api.connectmyfile.com/";

  appsName: any = "connectmyfile";

  header: any = null;
  userToken: any = "";

  constructor(private http: HttpClient, private popmodel: PopupmodelService) {
    this.updateAuthToken();
  }

  updateAuthToken() {
    this.userToken = "connect-my-file";
    if (sessionStorage.getItem("Auth-token")) {
      this.userToken = sessionStorage.getItem("Auth-token");
    }
    this.header = new HttpHeaders({
      "X-Auth-Token": this.userToken,
    });
  }

  // , {headers: this.header}
  getDataByPage(pageName: any) {
    return new Promise((resolve) => {
      this.http.get(this.serviceUrl + pageName).subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log(err);
        }
      );
    });
  }

  getDataByUrl(pageName: any) {
    return new Promise((resolve) => {
      this.http.get(pageName).subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log(err);
        }
      );
    });
  }

  postDataByJson(pageName: any, pageData: any) {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.serviceUrl + pageName, JSON.stringify(pageData))
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  postDataByForm(pageName: any, pageData: any) {
    return new Promise((resolve, reject) => {
      this.http.post(this.serviceUrl + pageName, pageData).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  whiteLabelSettings() {
    let requestURL = window.location.href;
    let splashRequest = requestURL.split("/");
    let splashRequest1 = splashRequest[2].split(".");
    if (splashRequest1[0] == "www") {
      requestURL = splashRequest1[1];
      if (requestURL == "portal") {
        requestURL = splashRequest1[2];
      }
    } else {
      requestURL = splashRequest1[0];
      if (requestURL == "portal") {
        requestURL = splashRequest1[1];
      }
    }
    if (requestURL == "localhost") {
      requestURL = this.appsName;
    }
    console.log(requestURL);
    this.popmodel.presentLoading();
    let formdata = new FormData();
    formdata.append("Whitelabel", requestURL);
    this.postDataByForm("users/get_whitelabel", formdata).then((data: any) => {
      this.popmodel.dismisLoading();
      console.log(data);
      if (data.status == 1) {
        let records = data.data.whiteRows;
        sessionStorage.setItem("whiteLabel", JSON.stringify(records));
        sessionStorage.setItem("whiteId", records.Id);
        sessionStorage.setItem("appLogo", records.Logo);
        sessionStorage.setItem("appName", records.Company_Name);
        sessionStorage.setItem("appVersion", records.appVersion);
        let theme = records.Theme_settings;
        theme = theme[0];
        console.log(theme);
        sessionStorage.setItem("webColor", theme.Main_Color);
        document.documentElement.style.setProperty(
          "--ion-color-primary",
          theme.Main_Color
        );
        document.documentElement.style.setProperty(
          "--ion-color-primary-text",
          theme.Main_Color + "CC"
        );
        document.documentElement.style.setProperty(
          "--ion-color-primary-50",
          theme.Main_Color + "80"
        );
        document.documentElement.style.setProperty(
          "--ion-color-primary-25",
          theme.Main_Color + "2e"
        );
        document.documentElement.style.setProperty(
          "--ion-color-primary-contrast",
          theme.Contrast_Color
        );
        document.documentElement.style.setProperty(
          "--ion-color-primary-shade",
          theme.Main_Color
        );
        document.documentElement.style.setProperty(
          "--ion-color-primary-tint",
          theme.Main_Color + "80"
        );
      }
    });
  }
}
