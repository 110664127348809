import { Injectable } from '@angular/core';
import { AlertController, ToastController, LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class PopupmodelService {
  loading: any;
  constructor(
      private alertController: AlertController,
      private toastController: ToastController, 
      private loadingCtrl: LoadingController
    ) { }
    setFirstLetterToUppercase(string : string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    async presentToast(msgTitle: string, position: any = "bottom") {
      const toast = await this.toastController.create({
        message: msgTitle,
        duration: 5000,
        position: position
      });
      toast.present();
    }
    async presentAlert(headerMsg: string = "Saved !", messageMsg="Record saved successfully.") {
      const alertCtr = await this.alertController.create({
        header: headerMsg,
        message: messageMsg,
        buttons: [
          {
            text: 'Ok',
            cssClass: 'doc-button-primary',
            handler: () => {
              console.log('action done');
            }
          }
        ]
      });
      await alertCtr.present();
    }
    async presentLoading(loadMessage: string = 'Please wait...') {
      this.loading = await this.loadingCtrl.create({
        duration: 2500,
        message: loadMessage,
        translucent: true
      });
      return await this.loading.present();
    }
    dismisLoading(){
      if(this.loading){
        this.loading.dismiss();
      }
    }
}
